.dispensing-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f7f7f7; /* Light background color */
}

.dispensing-content {
  margin-top: 50px;
}

.loader {
  width: 40px;
  height: 40px;
  aspect-ratio: 1;
  --c: linear-gradient(#000 0 0);
  --r1: radial-gradient(farthest-side at bottom,#000 93%,#0000);
  --r2: radial-gradient(farthest-side at top   ,#000 93%,#0000);
  background: 
    var(--c) ,var(--r1),var(--r2),
    var(--c) ,var(--r1),var(--r2),
    var(--c) ,var(--r1),var(--r2);
  background-repeat: no-repeat;
  animation: l1 1s infinite alternate;
  margin: auto;
  margin-top: 50px;
  justify-content: center;
}
@keyframes l1 {
  0%,10% {
    background-size: 8px 0,8px 4px,8px 4px;
    background-position: 0 50%,0 calc(50% - 2px),0 calc(50% + 2px),50% 50%,50% calc(50% - 2px),50% calc(50% + 2px),100% 50%,100% calc(50% - 2px),100% calc(50% + 2px);
 }
 90%,100% {
    background-size: 8px 100%,8px 4px, 8px 4px;
    background-position: 0 50%,0 -2px,0 calc(100% + 2px),50% 50%,50% -2px,50% calc(100% + 2px),100% 50%,100% -2px,100% calc(100% + 2px);
 }
}